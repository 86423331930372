<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.BpuSst"
        :edit="!bpu.locked ? edit : null"
        :save="save"
        :cancel="cancel"
        :remove="!bpu.locked ? remove : null"
      >
        <h1 class="title" cy-data="cy-bpu-title">
          <breadcrumb location="/sst/bpu" text="BPU"></breadcrumb>
          <span data-test="sel-bpu-name">{{ bpu.name }}</span>
        </h1>
        <template slot="actions">
          <button
            v-if="
              !bpu.locked &&
              !editMode &&
              currentTab === 1 &&
              getRights($entitiesName.BpuSst).update
            "
            class="button is-primary"
            @click="importClick"
          >
            {{ $t("common.import") }}
          </button>
          <form
            v-if="!editMode && currentTab === 1"
            class="inline-form"
            method="post"
            target="_blank"
            :action="`${API}/bpu/${id}/export?sort=${sorting}`"
          >
            <button class="button is-primary">
              {{ $t("common.exporter") }}
            </button>
            <input type="hidden" name="token" :value="authToken" />
          </form>
        </template>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li
                :class="{ 'is-active': currentTab === 0 }"
                data-test="sel-bpu-info-tab"
                @click="changeTab(0)"
              >
                <a>{{ $t("common.information") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 1 }"
                cy-data="bpu-prestations-tab"
                data-test="sel-bpu-prestation-tab"
                @click="changeTab(1)"
              >
                <a>{{ $t("common.prestations") }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="panel-block is-block no-border" v-if="currentTab === 0">
          <text-field
            cy-data="name"
            :label="$t('common.name')"
            v-model="bpu.name"
            required
          ></text-field>
          <many2one-field
            :label="$t('common.contractorAgreement')"
            v-model="bpu.market"
            :fetch="$api.fetchSSTs"
            reference="edifice"
            @click="(item) => $router.push('/sst/market/' + item.id)"
            entity="subcontractor_market"
            :columns="columnsSST"
            cy-data="market"
            readonly
          ></many2one-field>
          <many2one-field
            :label="$t('common.template')"
            v-model="bpu.bpuTemplate"
            :fetch="$api.fetchTemplates"
            reference="name"
            @click="(item) => $router.push('/sst/bputemplate/' + item.id)"
            entity="bpu_template"
            :columns="columnsBPUTemplate"
            cy-data="template"
            readonly
          ></many2one-field>
        </div>
        <div class="panel-block" v-if="currentTab == 1">
          <flexi-table
            :style="{ width: '100%' }"
            :columns="prestationColumns"
            :loader="fetchPrestations"
            :showIndex="true"
            @input="updatePrestations"
            ref="prestationsTable"
            @sortBy="updateSort"
          >
            <template v-if="editMode" slot="extraHeaderRow">
              <td class="index"></td>
              <td v-for="(c, i) in prestationColumns" :key="i">
                <input
                  v-if="c.name !== 'cost'"
                  v-model="c.filter"
                  class="input"
                  type="text"
                />
                <input v-else v-model="c.filter" class="input" type="number" />
              </td>
            </template>
            <template slot="dataRows" slot-scope="{ item, index }">
              <tr
                v-if="
                  !editMode &&
                  item.prestationSST.cost &&
                  +item.prestationSST.cost !== 0
                "
              >
                <td>{{ index + 1 }}</td>
                <td>
                  <div
                    class="nowrap"
                    :cy-data="`cy-bpu-prestation-code-${item.code}`"
                  >
                    {{ item.code }}
                  </div>
                </td>
                <td>{{ item.description }}</td>
                <td>{{ item.unit.name }}</td>
                <td :cy-data="`cy-bpu-prestation-price-${item.code}`">
                  {{ item.prestationSST.cost | priceEUR }}
                </td>
              </tr>
              <tr class="table-edit-mode" v-if="editMode && filter(item)">
                <td class="index">{{ index + 1 }}</td>
                <td class="filterable">
                  <div class="nowrap">{{ item.code }}</div>
                </td>
                <td class="filterable">{{ item.description }}</td>
                <td class="filterable">{{ item.unit.name }}</td>
                <td v-if="item.locked" class="filterable">
                  {{ item.prestationSST.cost | priceEUR }}
                </td>
                <td v-else class="filterable">
                  <input
                    type="number"
                    class="input"
                    v-model="item.prestationSST.cost"
                    :cy-data="`cy-bpu-prestation-${item.code}`"
                    :data-test="`sel-bpu-prestation-${index}`"
                  />
                </td>
              </tr>
            </template>
          </flexi-table>
        </div>
      </div>
    </div>
    <input
      ref="sstFileInput"
      type="file"
      :style="{ display: 'none' }"
      name="bpuFile"
      accept="text/csv"
      @change="importFile"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

const initialSorting = "category.parent.name,category.name,description,ASC";

export default {
  name: "bpu",
  props: ["id"],
  data() {
    return {
      bpu: {},
      prestations: {},
      columnsSST: {
        "project.name": this.$t("common.project"),
        "agency.manager": this.$t("common.agency"),
        edifice: this.$t("common.edifice"),
        maxAmountDC4: this.$t("markets.columns.maxAmountDC4"),
        eotpST: this.$t("markets.columns.eotpSt"),
        tva: this.$t("common.vat"),
        depositType: this.$t("common.retention"),
        paymentType: this.$t("markets.columns.paymentType"),
      },
      columnsBPUTemplate: {
        name: this.$t("common.reference"),
      },
      prestationColumns: [
        {
          title: this.$t("common.code"),
          name: "code",
          accessor: "code",
          filter: "",
          sort: "code",
          class: "sortable",
        },
        {
          title: this.$t("common.designation"),
          name: "description",
          accessor: "description",
          filter: "",
          sort: "description",
          class: "sortable",
        },
        {
          title: this.$t("common.unit"),
          name: "unit",
          accessor: "unit.name",
          filter: "",
          sort: "unit.name",
          class: "sortable",
        },
        {
          title: this.$t("prestations.columns.unitPrice"),
          name: "cost",
          accessor: "prestationSST.cost",
          filter: "",
        },
      ],
      currentTab: 0,
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
      sorting: initialSorting,
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
      getRights: "auth/getRights",
    }),
  },
  watch: {
    sorting() {
      this.$refs.prestationsTable.fetch();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.bpu = await this.$api.fetchBpu(this.id);
      this.fetchPrestations();
    },
    fetchPrestations(callback) {
      axios
        .get(`/bpu/${this.id}/prestations?sort=${this.sorting}`)
        .then((res) => {
          const withSST = res.data.map((p) => {
            const prestation = {
              ...p,
            };
            if (!p.prestationSST) {
              prestation.prestationSST = {
                bpuSST: this.bpu,
                cost: "0",
                prestation: p,
                id: null,
              };
            }
            return prestation;
          });
          if (typeof callback === "function") {
            callback(withSST);
            return;
          }
          this.updatePrestations(withSST);
        });
    },
    updatePrestations(val) {
      this.prestations = val;
    },
    save() {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          const bpu = {
            ...this.bpu,
            prestationSSTs: this.prestations.filter(
              (p) =>
                (p.prestationSST.cost !== "0" && p.prestationSST.cost !== "") ||
                p.prestationSST.id !== null
            ),
          };
          return axios
            .put(`/bpu/${this.id}`, bpu)
            .then(() => {
              // do nothing
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.error(e);
            })
            .finally(() => {
              if (this.$refs.prestationsTable) {
                this.$refs.prestationsTable.fetch();
              }
              this.$store.dispatch("states/setEdition", false);
            });
        }
        return Promise.resolve();
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      axios.get(`/bpu/${this.id}`).then((response) => {
        this.bpu = response.data;
        this.$store.dispatch("states/setEdition", false);
      });
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios.delete(`/bpu/${this.id}`).then((response) => {
            if (response.data.success) {
              this.$router.replace("/sst/bpu");
            }
          })
      );
    },
    filter(item) {
      return this.prestationColumns.every((p) => {
        const value = this.getProperty(item, p.accessor);

        // Escape all the characters provided by the user that could be interpreted in the Regex
        const filter = p.filter.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

        // Prepare the regex
        const rx = new RegExp(`.*${filter}.*`, "gi");

        // Evaluate
        return rx.test(value);
      });
    },
    getProperty(obj, accessor) {
      const props = accessor.split(".");
      let result = obj;
      for (let i = 0; i < props.length; i += 1) {
        const p = props[i];
        if (!result[p]) {
          return "";
        }
        result = result[p];
      }
      return result;
    },
    changeTab(tab) {
      // if editing is open, make sure they know they'll lose data
      if (this.editMode) {
        this.$awn.confirm(
          "Le changement d'onglet en mode édition peut provoquer une perte de donnée.",
          () => {
            this.currentTab = tab;
          }
        );
        return;
      }
      this.currentTab = tab;
    },
    importClick() {
      // eslint-disable-next-line
      this.$refs.sstFileInput.click();
    },
    importFile(e) {
      if (e.target.files[0]) {
        const form = new FormData();
        form.set("file", e.target.files[0]);
        axios
          .post(`/bpu/${this.id}/import`, form, {
            headers: { "Content-Type": "charset=UTF-8" },
          })
          .then(() => {
            this.$refs.prestationsTable.fetch();
            // eslint-disable-next-line
            alert("Import effectué avec succès");
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.error(err);
          });
      }
    },
    updateSort(newValue) {
      if (newValue === "initialValue") {
        this.sorting = initialSorting;
        return;
      }

      this.sorting = newValue;
    },
  },
};
</script>

<style lang='scss' scoped>
.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.no-border {
  border: none;
  div {
    width: 100%;
    span {
      width: 30%;
      display: inline-block;
    }
  }
}

tr {
  td.filterable {
    min-width: 18%;
  }

  div.nowrap {
    white-space: nowrap;
  }
}

.inline-form {
  display: inline;
}
</style>
