<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.OsTemplate"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <h1 class="title">
          <breadcrumb
            location="/os/templates"
            :text="$t('entities.orderTemplate')"
          ></breadcrumb>
          <span data-test="sel-ostemplate-name">{{ osTemplate.name }}</span>
        </h1>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li
                :class="{ 'is-active': currentTab == 0 }"
                @click="currentTab = 0"
              >
                <a>{{ $t("common.information") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab == 1 }"
                @click="currentTab = 1"
              >
                <a>{{ $t("common.prestations") }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="panel-block no-border is-block" v-if="currentTab == 0">
          <text-field
            :label="$t('expressions.templateName')"
            v-model="osTemplate.name"
            required
          ></text-field>
          <many2one-field
            :label="$t('common.template')"
            v-model="osTemplate.bpuTemplate"
            :fetch="$api.fetchTemplates"
            reference="name"
            @click="(item) => $router.push('/sst/bputemplate/' + item.id)"
            entity="bpu_template"
            :columns="columnsBPUTemplate"
            readonly
          ></many2one-field>
        </div>

        <div class="panel-block" v-if="currentTab == 1">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th class="has-text-left">
                  <button
                    class="button is-small is-info"
                    :class="{ 'is-loading': expandLoading }"
                    :title="$t('orderTemplates.switchAll')"
                    @click="toggleExpand"
                  >
                    <i class="fa fa-plus-square" />/<i
                      class="fa fa-minus-square"
                    />
                  </button>
                  {{ $t("common.code") }}
                </th>
                <th class="has-text-left">{{ $t("common.description") }}</th>
                <th></th>
                <th class="checkbox-column">{{ $t("common.display") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="editMode">
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <select-all
                    v-model="osTemplate.prestations"
                    :entities="prestations"
                    pKey="id"
                  />
                </td>
              </tr>
              <template v-for="(prestas, category) in prestaByCategory">
                <tr class="category" :key="'category_' + category">
                  <td colspan="2" @click="toggleDisplay(prestas.category)">
                    <i
                      class="fa"
                      :class="
                        displayed[prestas.category.id]
                          ? 'fa-minus-square'
                          : 'fa-plus-square'
                      "
                    ></i>
                    {{ prestas.category.name }}
                  </td>
                  <td
                    class="has-text-right"
                    @click="toggleDisplay(prestas.category)"
                  >
                    <b>{{ prestaPerCategory[prestas.category.id] }}</b> /
                    <b>{{ prestas.prestations.length }}</b>
                  </td>
                  <td>
                    <span v-if="editMode">
                      <select-all
                        label=""
                        v-model="osTemplate.prestations"
                        :entities="prestas.prestations"
                        pKey="id"
                      />
                    </span>
                  </td>
                </tr>
                <template v-if="displayed[prestas.category.id]">
                  <tr
                    v-for="prestation in prestas.prestations"
                    :key="'prestation_' + category + '_' + prestation.id"
                  >
                    <td class="has-text-left">{{ prestation.code }}</td>
                    <td class="has-text-left" colspan="2">
                      {{ prestation.description }}
                    </td>
                    <td>
                      <checkbox-field
                        :value="prestation.id"
                        v-model="osTemplate.prestations"
                        :inline="false"
                      />
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  props: ["id"],
  name: "osTemplateList",
  data() {
    return {
      osTemplate: {
        bpuTemplate: {},
        prestations: [],
      },
      currentTab: 0,
      displayed: {},
      columnsBPUTemplate: {
        name: this.$t("common.reference"),
      },
      prestaByCategory: [],
      prestations: [],
      expandLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
      getRights: "auth/getRights",
    }),
    prestaPerCategory() {
      return this.prestaByCategory.reduce((acc, elem) => {
        const counter = elem.prestations.filter((p) =>
          this.osTemplate.prestations.includes(p.id)
        ).length;
        acc[elem.category.id] = counter;
        return acc;
      }, {});
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(`/orderTemplate/${this.id}`).then((response) => {
        this.$store.dispatch("states/setEdition", false);
        this.osTemplate = response.data;

        axios
          .get(`/bputemplate/${response.data.bpuTemplate.id}/prestations`)
          .then((response2) => {
            const prestaByCategory = {};
            response2.data.forEach((record) => {
              const parentCategory = record.category.parent
                ? record.category.parent
                : { id: 0, name: "(Sans catégorie)" };

              if (!prestaByCategory[parentCategory.id]) {
                prestaByCategory[parentCategory.id] = {
                  category: parentCategory,
                  prestations: [],
                };
                this.$set(this.displayed, parentCategory.id, false);
              }
              prestaByCategory[parentCategory.id].prestations.push(record);
            });
            this.prestations = response2.data;
            this.prestaByCategory = Object.values(prestaByCategory).sort(
              (a, b) => a.category.name > b.category.name
            );
          });
      });
    },
    save() {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          return axios
            .put(`/orderTemplate/${this.id}`, this.osTemplate)
            .then((response) => {
              if (response.data.success) {
                this.$store.dispatch("states/setEdition", false);
              }
            });
        }
        return Promise.resolve();
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      this.fetchData();
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios
            .delete(`/orderTemplate/${this.id}/delete`, this.osTemplate)
            .then((response) => {
              if (response.data.success) {
                this.$router.replace("/os/templates");
              }
            })
      );
    },
    toggleDisplay(category) {
      this.displayed[category.id] = !this.displayed[category.id];
    },
    toggleExpand() {
      this.expandLoading = true;
      setTimeout(() => {
        const isNotExpanded = Object.values(this.displayed).includes(false);

        if (isNotExpanded) {
          Object.keys(this.displayed).forEach((k) => {
            this.displayed[k] = true;
          });
        } else {
          Object.keys(this.displayed).forEach((k) => {
            this.displayed[k] = false;
          });
        }
        this.expandLoading = false;
      }, 50);
    },
  },
};
</script>

<style lang='scss' scoped>
.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.no-border {
  border: none;
  div {
    width: 100%;
    span {
      width: 30%;
      display: inline-block;
    }
  }
}

.category td {
  border-color: #aaeefd;
}

.checkbox-column {
  min-width: 200px;
}

.category {
  cursor: pointer;
  background-color: lighten(#019fc4, 56%); /* #019fc4; */
  /*  color: #000;*/

  i.fa {
    margin-right: 10px;
  }
}
</style>
