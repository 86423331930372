<template>
  <modal
    ref="sendToValidationModal"
    @open="fetchRequiredRolesForValidation"
    @close="reset"
    :action="$t('common.send')"
    :loading="isSending"
    @create="setEnvelopeValidators"
    :disabled="isDisabled"
    :title="$t('envelopes.validation.validatorSelectionModalTitle')"
  >
    <div class="columns">
      <div class="column">
        <div v-for="(role, idx) in roles" :key="role.id">
          <many2one-field
            :label="role.name"
            :fetch="fetchUsersByRole"
            v-model="selectedUsers[idx]"
            reference="name"
            @activated="() => (selectedRoleId = role.id)"
            @click="(item) => $router.push('/project/' + item.id)"
            :columns="userColumns"
            :inline="true"
            :edit="true"
            required
            :singleSelect="true"
            :hasFilter="true"
            name="role2one"
            data-vv-name="role2one"
            v-validate="{ required: true }"
            :error="errors.has('role2one')"
          ></many2one-field>
        </div>
        <div v-if="roles.length < 2">
          <hr />
          <span class="has-text-danger">
            {{ $t("envelopes.validation.atLeast2RolesRequired") }}
          </span>
        </div>
        <div v-if="!eachRoleSelectedAUser && roles.length > 2">
          <hr />
          <span class="has-text-danger">
            {{ $t("envelopes.validation.eachRoleMustSelectAUser") }}
          </span>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";

export default {
  name: "SendToValidationModal",
  props: {
    envelopeId: [String, Number],
  },
  data() {
    return {
      roles: [],
      isSending: false,
      userColumns: {
        name: this.$t("common.name"),
        mail: "E-Mail",
        "role.name": this.$t("entities.role"),
        service: this.$t("common.service"),
      },
      selectedUsers: [],
      selectedRoleId: null,
    };
  },
  computed: {
    filteredSelectedUsers() {
      return this.selectedUsers.filter(Boolean);
    },
    eachRoleSelectedAUser() {
      return (
        this.filteredSelectedUsers.length === this.roles.length &&
        this.roles.length > 0
      );
    },
    isDisabled() {
      return !(
        this.envelopeId &&
        this.roles.length > 1 &&
        this.eachRoleSelectedAUser
      );
    },
  },
  methods: {
    open() {
      this.$refs.sendToValidationModal.open();
    },
    async fetchRequiredRolesForValidation() {
      try {
        const data = await this.$api.fetchRequiredRolesForValidation();
        this.roles = data.map((role) => ({
          id: role.id,
          name: role.name,
        }));
      } catch (error) {
        console.error("Failed to fetch roles:", error);
      }
    },
    fetchUsersByRole(query) {
      return this.$api.fetchUsersByRole(query, this.selectedRoleId);
    },
    async setEnvelopeValidators() {
      if (this.isDisabled) return;

      this.isSending = true;
      try {
        await axios.post("/envelope/validation/", {
          envId: this.envelopeId,
          users: this.filteredSelectedUsers,
        });
        this.$emit("created");
        this.$refs.sendToValidationModal.close();
      } catch (error) {
        console.error("Failed to send envelope for validation:", error);
        // You might want to handle the error, e.g., show a notification to the user.
      } finally {
        this.isSending = false;
      }
    },
    reset() {
      this.roles = [];
      this.selectedUsers = [];
      this.selectedRoleId = null;
    },
  },
};
</script>
