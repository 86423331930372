<template>
  <NotificationContainer :readedAt="readedAt">
    <div class="notification-detail" @click="open">
      <p class="text-muted">
        {{ popupNotification.title }}
      </p>
      <span class="text-muted"
        ><small>{{ createdAt | fromNow }}</small></span
      >
    </div>
    <popup-component
      ref="popup"
      :popupData="popupNotification"
      :notificationData="{}"
    >
    </popup-component>
  </NotificationContainer>
</template>

<script>
export default {
  name: "PopupNotification",
  props: {
    readedAt: {},
    template: {},
    createdAt: {},
    user: {},
    popupNotification: {},
  },
  data() {
    return {
      excerpt: null,
    };
  },

  // mounted() {
  //   Promise.all([this.parse()]);
  // },

  methods: {
    open() {
      this.$refs.popup.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-detail {
  width: 100%;
  cursor: pointer;
  p {
    margin-bottom: 5px;
    line-height: 1.2;
  }
}
</style>
