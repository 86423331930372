<template>
  <div v-if="visible" class="modal-background">
    <div class="modal-placeholder">
      <div class="modal-bg">
        <div class="header has-text-centered is-size-3">
          <h2 class="has-text-weight-bold">Évaluation projet</h2>
        </div>
        <div class="column" style="margin-bottom: 30px">
          <div class="comment-evaluation">
            <star-rating
              label="Qualité"
              subLabel="(i : Les travaux/prestation réalisés répondaient-ils aux attentes qualité exigées par Axione ?)"
              :number-of-stars="5"
              @rating-selected="handleRatingQuality"
            ></star-rating>
            <textarea
              class="textarea"
              v-model="evaluation.qualityComment"
              :required="evaluation.quality < 3"
              rows="1"
            ></textarea>
            <p
              v-if="
                formSubmitted &&
                evaluation.quality < 3 &&
                !evaluation.qualityComment
              "
              class="error-message"
            >
              Veuillez fournir un commentaire pour une note de 2 étoiles ou
              moins.
            </p>
          </div>

          <div class="comment-evaluation">
            <star-rating
              label="Technique"
              subLabel="(i : Le sous-traitant a-t-il été force de proposition concernant les attendus du chantier ? Innovation, compétence particulière, …)"
              :number-of-stars="5"
              @rating-selected="handleRatingTechnic"
            ></star-rating>
            <textarea
              class="textarea"
              v-model="evaluation.technicComment"
              :required="evaluation.technic < 3"
              rows="1"
            ></textarea>
            <p
              v-if="
                formSubmitted &&
                evaluation.technic < 3 &&
                !evaluation.technicComment
              "
              class="error-message"
            >
              Veuillez fournir un commentaire pour une note de 2 étoiles ou
              moins.
            </p>
          </div>

          <div class="comment-evaluation">
            <star-rating
              label="Délais et réactivité"
              subLabel="(i : L'offre financière a-t-elle été remise dans des délais raisonnables ? Les délais d'intervention initiaux ont-ils été respectés ?)"
              :number-of-stars="5"
              @rating-selected="handleRatingReactivity"
            ></star-rating>
            <textarea
              class="textarea"
              v-model="evaluation.reactivityComment"
              :required="evaluation.reactivity < 3"
              rows="1"
            ></textarea>
            <p
              v-if="
                formSubmitted &&
                evaluation.reactivity < 3 &&
                !evaluation.reactivityComment
              "
              class="error-message"
            >
              Veuillez fournir un commentaire pour une note de 2 étoiles ou
              moins.
            </p>
          </div>

          <div class="comment-evaluation">
            <star-rating
              label="Sécurité"
              subLabel="(i : Le sous-traitant respecte-t-il les règles de sécurité Axione et la réglementation liée ?)"
              :number-of-stars="5"
              @rating-selected="handleRatingSafety"
            ></star-rating>
            <textarea
              class="textarea"
              v-model="evaluation.safetyComment"
              :required="evaluation.safety < 3"
              rows="1"
            ></textarea>
            <p
              v-if="
                formSubmitted &&
                evaluation.safety < 3 &&
                !evaluation.safetyComment
              "
              class="error-message"
            >
              Veuillez fournir un commentaire pour une note de 2 étoiles ou
              moins.
            </p>
          </div>

          <div class="comment-evaluation">
            <star-rating
              label="Relationnel"
              subLabel="(i : Le sous-traitant était-il facilement joignable et disponible ? Aviez-vous comme interlocuteur un contact bien identifié et unique ? Les échanges étaient-ils fluides ?)"
              :number-of-stars="5"
              @rating-selected="handleRatingRelational"
            ></star-rating>
            <textarea
              class="textarea"
              v-model="evaluation.relationalComment"
              :required="evaluation.relational < 3"
              rows="1"
            ></textarea>
            <p
              v-if="
                formSubmitted &&
                evaluation.relational < 3 &&
                !evaluation.relationalComment
              "
              class="error-message"
            >
              Veuillez fournir un commentaire pour une note de 2 étoiles ou
              moins.
            </p>
          </div>
          <div class="skip-container">
            <input type="checkbox" v-model="skipEvaluation" />
            <p>
              Cocher cette case si vous avez déjà évalué, ou, ne pouvez pas
              évaluer cet attachement
            </p>
          </div>
        </div>
        <button
          style="margin-right: 20px; margin-left: 10px"
          class="button is-danger"
          @click="cancel"
        >
          Annuler
        </button>
        <button
          style="margin-right: 20px"
          class="button is-success"
          @click="submitForm"
          :disabled="skipEvaluation"
        >
          Soumettre
        </button>
        <button class="button" @click="skip" :disabled="!skipEvaluation">
          Passer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      formSubmitted: false,
      evaluation: {
        technic: 0,
        technicComment: "",
        safety: 0,
        safetyComment: "",
        relational: 0,
        relationalComment: "",
        quality: 0,
        qualityComment: "",
        reactivity: 0,
        reactivityComment: "",
      },
      skipEvaluation: false,
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
      this.formSubmitted = false; // Reset when closing the modal
    },
    handleRatingTechnic(rating) {
      this.evaluation.technic = rating;
    },
    handleRatingSafety(rating) {
      this.evaluation.safety = rating;
    },
    handleRatingRelational(rating) {
      this.evaluation.relational = rating;
    },
    handleRatingQuality(rating) {
      this.evaluation.quality = rating;
    },
    handleRatingReactivity(rating) {
      this.evaluation.reactivity = rating;
    },

    skip() {
      this.$emit("skip");
      this.close();
    },

    submitForm() {
      this.formSubmitted = true;
      if (
        (this.evaluation.technic < 3 && !this.evaluation.technicComment) ||
        (this.evaluation.safety < 3 && !this.evaluation.safetyComment) ||
        (this.evaluation.relational < 3 &&
          !this.evaluation.relationalComment) ||
        (this.evaluation.quality < 3 && !this.evaluation.qualityComment) ||
        (this.evaluation.reactivity < 3 && !this.evaluation.reactivityComment)
      ) {
        return;
      }
      this.$emit("submit", this.evaluation);
      this.close();
    },
    cancel() {
      this.$emit("cancel");
      this.close();
    },
  },
};
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
}
.comment-evaluation {
  margin-bottom: 25px;
}

.modal-placeholder {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  height: 100%;
}

.modal-bg {
  position: fixed;
  left: 40%;
  right: 0;
  top: 0;
  height: 100%;
  background-color: #fff;
  padding: 20px 10px;
  overflow: auto;
}
.modal-background {
  position: fixed !important;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 35;
}
.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
  margin-bottom: 20px;
}

.textarea {
  margin-top: 5px;
}

.skip-container {
  display: flex;
  align-items: center;
  p {
    margin-left: 10px;
    font-size: 0.9rem;
  }
}
</style>
