<template>
  <div class="home">
    <div class="container is-fluid">
      <div class="header">
        <div>
          <h1 class="title" cy-data="cy-popup-title">
            <breadcrumb
              location="/popups-management"
              :text="$t('entities.popups')"
            ></breadcrumb>
            {{ popup.title }}
          </h1>
        </div>
        <i v-if="loading" cy-data="loader" class="loader inline"></i>

        <div class="is-pulled-right">
          <button
            cy-data="cy-popup-preview"
            class="button is-success"
            :class="{ 'is-loading': isLoading }"
            @click="preview"
          >
            {{ $t("common.preview") }}
          </button>
          <button v-if="editMode"
                  class="button is-danger"
                  :cy-data="`cy-popup-cancel`"
                  :data-test="`sel-page-popup-cancel`"
                  @click="cancel"
          >{{$t('common.cancel')}}</button>
          <button
            cy-data="cy-popup-save"
            class="button is-success"
            :class="{ 'is-loading': isLoading }"
            @click="save"
            v-if="editMode || !id"
          >
            {{ $t("common.save") }}
          </button>
          <button
            cy-data="cy-popup-edit"
            class="button is-primary"
            :class="{ 'is-loading': isLoading }"
            @click="edit"
            v-if="!editMode && id"
          >
            {{$t('common.edition')}}
          </button>
          <button
            cy-data="`cy-popup-delete`"
            class="button is-danger"
            v-if="id"
            @click="remove"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li
                :class="{ 'is-active': currentTab === 0 }"
                cy-data="popup-content-tab"
                data-test="popup-content-tab"
                @click="switchToContentTab"
              >
                <a>{{ $t("popup.tabs.content") }}</a>
              </li>

              <li
                :class="{ 'is-active': currentTab === 1 }"
                cy-data="popup-users-tab"
                data-test="popup-content-tab"
                @click="switchToUserTab"
                v-if="id"
              >
                <a>{{ $t("popup.tabs.users") }}</a>
              </li>

              <li
                :class="{ 'is-active': currentTab === 2 }"
                cy-data="popup-info-tab"
                data-test="popup-content-tab"
                @click="switchToInfoTab"
                v-if="id"
              >
                <a>{{ $t("popup.tabs.information") }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="panel-block is-block no-border" v-if="currentTab === 0">
          <div class="columns">
            <div class="column">
              <popup-editor :value="popup" v-model="popup" :edit="editMode || !id" />
            </div>
          </div>
        </div>

        <div class="panel-block is-block no-border" v-if="currentTab === 1">
          <div class="columns">
            <div class="column">
              <div class="is-full-width is-pulled-right button-container">
                <button
                  class="button is-success"
                  @click="openAddUserModal"
                >
                  {{ $t("popup.addUsers") }}&nbsp;
                  <i class="fa fa-plus"></i>
                </button>
                <button
                  class="button is-danger"
                  @click="openRemoveUserModal"
                >
                  {{ $t("popup.removeUsers") }}&nbsp;
                  <i class="fa fa-minus"></i>
                </button>
              </div>
              <flexi-table
                class="is-full-width"
                :loader="fetchPopupUsers"
                :columns="columns.userTab"
                :showIndex="true"
                ref="popupUsersTable"
              >
                <template slot="dataRows" slot-scope="{ item, index }">
                  <tr>
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.mail }}</td>
                    <td>{{ item.role && item.role.name }}</td>
                    <td>
                      <button class="button is-small is-danger" @click="deleteUser(item)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </template>
              </flexi-table>
            </div>
          </div>
        </div>

        <div class="panel-block is-block no-border" v-if="currentTab === 2">
          <div class="columns">
            <div class="column">
              <datatable
                :fetch="fetchPopupInformation"
                ref="informationList"
                :enableDownload="false"
                noSelection
                objectName="popup-information"
              >
                <div
                  slot="name"
                  slot-scope="{ item }"
                  :title="$t('common.name')"
                >
                  {{ item.user && item.user.name }}
                </div>
                <div
                  slot="mail"
                  slot-scope="{ item }"
                  :title="$t('common.email')"
                >
                  {{ item.user && item.user.mail }}
                </div>
                <div
                  slot="role"
                  slot-scope="{ item }"
                  :title="$t('common.profile')"
                >
                  <span>{{item.user && item.user.role && item.user.role.name }}</span>
                </div>
                <div
                  slot="read-at"
                  slot-scope="{ item }"
                  :title="$t('popup.readedAt')"
                  :nofilter="false"
                >
                  <span>
                    {{ item.readedAt | date }}
                    <checkbox-field v-if="item.readedAt === null" :value="false" :edit="false" :inline="true" />
                  </span>
                </div>
                <div
                  slot="closed-at"
                  slot-scope="{ item }"
                  :title="$t('popup.closedAt')"
                  :nofilter="false"
                >
                  <span>
                    {{ item.closedAt | date }}
                    <checkbox-field v-if="item.closedAt === null" :value="false" :edit="false" :inline="true" />
                  </span>
                </div>
              </datatable>
            </div>
          </div>
        </div>
      </div>

      <modal
        class="add-users-modal"
        :title="isDeleting ? $t('popup.removeUsers') : $t('popup.addUsers')"
        ref="selectionModal"
        :action="isDeleting ? $t('common.delete') : $t('common.add')"
        @create="$refs.userListModal.open()"
        @close="closeUserModal"
      >
        <many2one-field
          :label="$t('entities.roles')"
          v-model="usersFilter.roles"
          :fetch="$api.fetchRoles"
          reference="name"
          entity="role"
          :columns="columns.role"
          :inline="true"
          :edit="true"
          :hasFilter="true"
          name="role2one"
        ></many2one-field>

        <many2one-field
          :label="$t('common.agencies')"
          v-model="usersFilter.agencies"
          :fetch="$api.fetchCompanies"
          reference="name"
          entity="company"
          :columns="columns.agency"
          :inline="true"
          :edit="true"
          :hasFilter="true"
          name="agency2one"
        ></many2one-field>

        <many2one-field
          :label="$t('common.projects')"
          v-model="usersFilter.projects"
          :fetch="$api.fetchProjects"
          reference="name"
          entity="project"
          :columns="columns.project"
          :inline="true"
          :edit="true"
          :hasFilter="true"
          name="project2one"
        ></many2one-field>
      </modal>
      <modal
        class="list-users-modal"
        title="Confirmer la selection des utilisateurs"
        ref="userListModal"
        action="Confirmer"
        @create="closeConfirmationModal"
      >
        <datatable
          singleSelect
          :fetch="fetchUsers"
          :columns="columns.user"
          reference="name"
          noSelection
          :size="15"
        ></datatable>
      </modal>
      <popup-component preview ref="previewPopup" :popupData="popup"> </popup-component>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import PaginationBuilder from "@/lib/Paginate";

export default {
  props: ["id"],
  name: "popup-view",
  computed: {
    ...mapGetters({
      loading: "states/isLoading",
      editMode: 'states/isEdition',
    }),
  },
  data() {
    return {
      currentTab: 0,
      isLoading: false,
      popup: {
        active: false,
      },
      isDeleting: false,
      popupUserList: [],
      fetchedUserList: [],
      usersToDelete: [],
      usersFilter: {
        roles: [],
        projects: [],
        agencies: [],
      },
      columns: {
        role: {
          name: {
            title: this.$t("common.name"),
            filter: "",
          },
          description: {
            title: this.$t("common.description"),
            filter: "",
          },
        },
        agency: {
          name: this.$t("common.name"),
          siren: this.$t("common.siren"),
          manager: this.$t("common.manager"),
          edifice: this.$t("common.agency"),
        },
        project: {
          name: this.$t("common.name"),
          code: this.$t("common.code"),
          "type.name": this.$t("common.type"),
        },
        user: {
          name: this.$t("common.name"),
          mail: "E-Mail",
          "role.name": this.$t("entities.role"),
          service: this.$t("common.service"),
        },
        userTab: [
          {
            title: this.$t("common.name"),
            name: "name",
            accessor: "name",
          },
          {
            title: this.$t("common.email"),
            name: "mail",
            accessor: "mail",
          },
          {
            title: this.$t("common.profile"),
            name: "role",
            accessor: "role.name",
          },
        ],
        informationTab: [
          {
            title: this.$t("common.name"),
            name: "name",
            accessor: "name",
          },
          {
            title: this.$t("common.email"),
            name: "mail",
            accessor: "mail",
          },
          {
            title: this.$t("common.profile"),
            name: "role",
            accessor: "role.name",
          },
        ],
      },
    };
  },
  mounted() {
    if (this.id) this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(`/popup-notification/${this.id}`).then((response) => {
        this.popup = response.data;
      });
    },
    fetchPopupUsers(callback) {
      axios
        .get(`/popup-notification/${this.id}/users`)
        .then((response) => {
          callback(response.data.content);
          this.popupUserList = response.data.content;
        });
    },
    save() {
      if (!this.id) {
        axios.post("/popup-notification/", this.popup).then((response) => {
          if (response.data.success) {
            this.$router.replace(`/popups-management/${response.data.id}`);
          }
        });
        return;
      }
      axios
        .put(`/popup-notification/${this.id}`, this.popup)
        .then((response) => {
          if (response.data.success) {
            this.$store.dispatch("states/setEdition", false);
            this.fetchData();
          }
        });
    },
    preview() {
      this.$refs.previewPopup.open();
    },
    remove() {
      return this.$awn.confirm(this.$t("expressions.delete"), () =>
        axios.delete(`/popup-notification/${this.id}`).then((response) => {
          if (response.data.success) {
            this.$router.replace("/popups-management");
          }
        })
      );
    },
    openAddUserModal() {
      this.isDeleting = false;
      this.$refs.selectionModal.open();
    },
    openRemoveUserModal() {
      this.isDeleting = true;
      this.$refs.selectionModal.open();
    },
    closeUserModal()
    {
      this.usersFilter.roles = [];
      this.usersFilter.projects = [];
      this.usersFilter.agencies = [];
    },
    closeConfirmationModal()
    {
      this.isDeleting ? this.removeUsers() : this.addUsers();
    },
    getUserFilters(encoded = false)
    {
      let filters = [];
      filters["roles"] = this.usersFilter.roles.map((p) => p.id).join(",");
      filters["agencies"] = this.usersFilter.agencies
        .map((p) => p.id)
        .join(",");
      filters["projects"] = this.usersFilter.projects
        .map((p) => p.id)
        .join(",");
      if(!encoded)
        return filters;

      return Object.keys(filters).map(
        k => `${encodeURIComponent(k)}=${encodeURIComponent(filters[k])}`,
      ).join('&')
    },
    fetchUsers(p) {
      p.filters = this.getUserFilters();
      const paginate = new PaginationBuilder(p);
      return axios
        .get(`/popup-notification/fetch-users?${paginate.toString()}`)
        .then((response) => {
          this.fetchedUserList = response.data.content;
          return response.data;
        });
    },
    addUsers() {
      this.fetchedUserList = [];

      const filters = this.getUserFilters(true);
      axios.get(`/popup-notification/fetch-users-without-page?${filters}`)
        .then((response) => {
          axios .post(`/popup-notification/${this.id}/users/add`, response.data)
            .then((response) => {
              if (response.data.success) {
                this.closeModals();
              }
            });
        });
    },
    removeUsers() {
      this.fetchedUserList = [];

      const filters = this.getUserFilters(true);
      axios.get(`/popup-notification/fetch-users-without-page?${filters}`)
        .then((response) => {
          axios .post(`/popup-notification/${this.id}/users/delete`, response.data)
            .then((response) => {
              if (response.data.success) {
                this.closeModals();
              }
            });
        });
    },
    closeModals()
    {
      this.$refs.selectionModal.close();
      this.$refs.userListModal.close();
      this.$refs.popupUsersTable.fetch();
    },
    switchToContentTab()
    {
      this.currentTab = 0
    },
    switchToUserTab()
    {
      this.currentTab = 1;
    },
    switchToInfoTab()
    {
      this.currentTab = 2;
    },
    deleteUser(user)
    {
      return this.$awn.confirm(this.$t("expressions.delete"), () => {
          this.usersToDelete = [ user ];
          this.deleteUsers()
        });
    },
    deleteUsers() {
      axios
        .post(`/popup-notification/${this.id}/users/delete`, this.usersToDelete)
        .then((response) => {
          if (response.data.success) {
            this.usersToDelete = [];
            this.$refs.popupUsersTable.fetch();
          }
        });
    },
    fetchPopupInformation(p) {
      const paginate = new PaginationBuilder(p);
      return axios.get(`/popup-notification/${this.id}/notifications?${paginate.toString()}`)
        .then((response) => {
          return response.data;
        });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      this.$store.dispatch("states/setEdition", false);
    }
  },
};
</script>

<style lang="scss" scoped>
.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.inline {
  display: inline-block;
  border-left-color: #0195b7;
  border-bottom-color: #0195b7;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .loader {
    margin-right: auto;
    margin-left: 10px;
  }
  .is-pulled-right > * {
    margin-left: 4px;
  }
}

.is-full-width {
  flex: 1;
  width: 100%;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  .button {
    margin-left: 5px;
  }
}
</style>
